/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common'  : {
      init    : function () {
        // JavaScript to be fired on all pages

        // Navigation
        if (Cookies.get("sidenav-state") !== "") {
          $('main').addClass(Cookies.get("sidenav-state"));
        }

        if (window.matchMedia('(max-width: 663px)').matches) {
          $('main').addClass('sidenav-collapse');
        }

        $('.sidenav-toggle, .sidenav-toggle-hamb').click(function () {
          $('body').addClass('sidenav-transition');
          $('main').toggleClass('sidenav-collapse');
          $('#sub-nav').addClass('sub-nav-hidden');

          if (window.matchMedia('(min-width: 664px)').matches) {
            if ($('main').hasClass('sidenav-collapse')) {
              Cookies.get("sidenav-state", "sidenav-collapse", {path: "/", expires: 30});
            } else {
              Cookies.get("sidenav-state", "", {path: "/", expires: 30});
            }
          } else {
            Cookies.get("sidenav-state", "sidenav-collapse", {path: "/", expires: 30});
          }
        });

        $('.products-link').click(function () {
          $('#sub-nav').toggleClass('sub-nav-hidden');
        });

        // Filters
        $('.js-filter-toggle').click(function () {
          $(this).next().toggleClass('is-hidden');
        });

        // $('input').focusout(function() {
        //   $(this).parent('.form-row').removeClass('is-active');
        // });

        $('input').focusin(function () {
          $(this).parent('.form-row').addClass('is-active');
        });

        $('input').focusout(function () {
          if (!$(this).val()) {
            $(this).parent('.form-row').removeClass('is-active');
          }
        });

        // Focus search input
        $(document).on('opened', '.remodal', function () {
          $("#yith-s").focus();
        });




      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired

        // SVG polyfill for IE9-11
        svg4everybody();
      }
    },
    // Home page
    'home'    : {
      init    : function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    },
    // Checkout page
    'checkout': {
      init: function () {
        // //    $('#username').attr( 'placeholder', 'KÄYTTÄJÄTUNNUS' );
        // //    $('#password').attr( 'placeholder', 'SALASANA' );


        //
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire      : function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);


})(jQuery); // Fully reference jQuery after this point.
